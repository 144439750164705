import React from "react";
import { Route, Switch } from "react-router-dom";
import InspectorPage from "./pages/Inspector/InspectorPage";
import MapPage from "./pages/Map/MapPage";
import StatisticsPage from "./pages/Statistics/StatisticsPage";

export default () =>
  <Switch>
    <Route exact path="/" component={MapPage} />
    <Route path="/inspect" component={InspectorPage} />
    <Route path="/statistics" component={StatisticsPage} />
  </Switch>;
