export interface TransSecIts {
  dataType: DataType;
  location: Location;
  timestamp: Date;
}

export interface Water extends TransSecIts {
  deviceId: string;
  temperature: string;
  locality: string;
  graph: string;
}

export interface Met extends TransSecIts {
  deviceId: string;
  temperature: string;
  humidity: string;
  pressure: string;
  rain_1h: string;
  windGust: string;
  windSpeed: string;
  windDir: string;
  locality: string;
  graph: string;
}

export interface CWOP extends TransSecIts {
  locality: string;
  deviceId: string;
  temperature: string;
  wind_direction:string;
  wind_speed:string;
  wind_gust:string;
  pressure:string;
  rain_1h:string;
  rain_24h:string;
  humidity:string;
  dew_point:string;
  wind_chill:string;
  rain_since_midnight:string;
  graph:string;
}

export interface Agri extends TransSecIts {
  deviceId: string;
  temperature: string;
  humidity: string;
  pressure: string;
  rainPrevHr: string;
  rainPre24Hr: string;
  windSpeed: string;
  windDir: string;
  locality: string;
}

export interface Weewx extends TransSecIts {
  locality: string;
  deviceId: string;
  outTemp_C: string;
  windSpeed_kph: string;
  windDir: string;
  windGust_kph: string;
  pressure_mbar: string;
  outHumidity: string;
  dewpoint_C: string;
  hourRain_cm: string;
  rain24_cm: string;
  graph: string;
}


export interface Air extends TransSecIts {
  locality: string;
  deviceId: string;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export enum DataType {
  Unknown = 0,
  Water = 1,
  Agri = 2,
  CWOP = 3,
  Met = 4,
  Weewx = 5,
  Air = 6,
}


export function getDataType(dataType: DataType) {
  switch (dataType) {
  case DataType.Water:
    return "SmartWater";
  case DataType.Agri:
    return "SmartAgri";
  case DataType.CWOP:
    return "CWOP Weather Observations";
  case DataType.Met:
    return "Met Eireann Weather Observations";
  case DataType.Weewx:
    return "Weewx";
  case DataType.Air:
    return "AirQual";
  default:
    return "Unknown";
  }
}
