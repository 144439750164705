import { TransSecIts, DataType } from "./interfaces/TransSecDataTypes";
import L, { LatLng } from "leaflet";

/**
 * @param object The TransSecIts object to get value
 *
 * Returns a leaflet LatLng object created from [lat, lon]
 * position contained within the specified object.
 */
export function GetLatLng(object: TransSecIts): LatLng {
  return L.latLng(object.location.latitude,
    object.location.longitude);
}

/**
 * @param object The TransSecIts object to get value
 *
 * Selects the appropriate icon for the object parameter and
 * returns a new Leaflet icon.
 */
export function GetMarkerIcon(object: TransSecIts): L.Icon<L.IconOptions> {
  let iconUrl = "";
  switch (object.dataType) {
  case DataType.Water:
    iconUrl = "icons/icon2.svg";
    break;
  case DataType.Met:
    iconUrl = "icons/icon1.svg";
    break;
  case DataType.CWOP:
    iconUrl = "icons/icon1.svg";
    break;
  case DataType.Agri:
    iconUrl = "icons/icon1.svg";
    break;
  case DataType.Weewx:
    iconUrl = "icons/icon1.svg";
    break;
  case DataType.Air:
    iconUrl = "icons/icon1.svg";
    break;
  default:
    iconUrl = "icons/icon1.svg";
    break;
  }
  return L.icon({
    iconAnchor: [10, 20],
    iconSize: [20, 20],
    iconUrl,
    popupAnchor: [0, -3],
  });
}

/**
 * @param pos The lat/lon coordinate to convertmet
 * @param map The map instance
 *
 * Returns [x, y] coordinates relative to map instance of a specified
 * [Lat, Lon] position.
 */
export function ToLayerPoint(pos: L.LatLngExpression, map: L.Map) {
  return map.latLngToLayerPoint(pos);
}

/**
 * @param points The list of points to examine
 *
 * Calculates the central point in a group of
 * 2D Point coordinates.
 */
export function AveragePoint(points: L.Point[]) {
  let x = 0;
  let y = 0;

  for (const point of points) {
    x += point.x;
    y += point.y;
  }
  x /= points.length;
  y /= points.length;

  return L.point(x, y);
}

/**
 * @param count The number of points
 * @param center The position of the spiral center
 *
 * Generates a list points positioned along a spiral
 * generated around the specified center point. A spiral is used
 * in situations where there are a large number of markers within a
 * cluster, to prevent overlap.
 */
export function GeneratePointsSpiral(count: number, center: L.Point) {
  const twoPi = Math.PI * 2;

  const spiderfyDistanceMultiplier = 1;
  const spiralFootSeparation = 20;
  const spiralLengthStart = 10;
  const spiralLengthFactor = 1;

  let legLength = spiderfyDistanceMultiplier * spiralLengthStart;
  const separation = spiderfyDistanceMultiplier * spiralFootSeparation;
  const lengthFactor = spiderfyDistanceMultiplier * spiralLengthFactor * twoPi;

  let angle = 0;

  const result = new Array<L.Point>();

  for (let i = count; i >= 0; i--) {
    if (i < count) {
      const point = new L.Point(center.x + legLength * Math.cos(angle),
        center.y + legLength * Math.sin(angle));
      result.unshift(point);
    }
    angle += separation / legLength + i * 0.0005;
    legLength += lengthFactor / angle;
  }
  return result;
}

/**
 * @param count The number of points
 * @param center The position of the circle center
 *
 * Generates a list points positioned along the circumference
 * of a circle generated around the specified center point.
 */
export function GeneratePointsCircle(count: number, center: L.Point) {
  const twoPi = Math.PI * 2;
  const circleFootSeparation = 30;
  const circleStartAngle = 0;
  // Increase to increase the distance away that spiderfied markers appear
  // from the center
  const spiderfyDistanceMultiplier = 1;

  const circumference = spiderfyDistanceMultiplier * circleFootSeparation *
    (2 + count);
  const legLength = circumference / twoPi;
  const angleStep = twoPi / count;

  const result = new Array<L.Point>();

  // Generate the points along circle circumference
  for (let i = 0; i < count; i++) {
    const angle = circleStartAngle + i * angleStep;
    const point = new L.Point(center.x + legLength * Math.cos(angle),
      center.y + legLength * Math.sin(angle));
    result.push(point);
  }

  return result;
}

/**
 * Data type for handling easy reference of TransSecIts objects
 * and Leaflet Map Points.
 */
export interface ItsPoint {
  object: TransSecIts;
  point: L.Point;
}

/**
 * @param a The origin point
 * @param b The destination point
 *
 * Returns the distance between 2D points a and b, using
 * the formula √((x₂ - x₁)² + (y₂ - y₁)²).
 */
export function CalculateDistance(a: L.Point, b: L.Point) {
  return Math.hypot(b.x - a.x, b.y - a.y);
}

/**
 * Given multiple lists of ITS objects, find the approximate center
 * latitude/longitude coordinates.
 */
export function FindCentreCoords(...args: TransSecIts[][]) {
  let lat = 0;
  let lon = 0;
  let count = 0;
  for (const array of args) {
    for (const object of array) {
      lat += object.location.latitude;
      lon += object.location.longitude;
      count++;
    }
  }
  lat /= count;
  lon /= count;
  return { latitude: lat, longitude: lon };
}

/**
 * Returns the total number of active ITS objects.
 */
export function GetTotalActiveObjects(...args: TransSecIts[][]) {
  let count = 0;
  for (const array of args) {
    count += array.length;
  }
  return count;
}

/**
 * @param origin The starting point coordinates
 * @param destination The destination point coordinates
 *
 * Calculates the distance, in meters, between an origin and
 * destination latitude/latitude coordinates.
 */
export function GetDistance(
  origin: { latitude: number, longitude: number },
  destination: { latitude: number, longitude: number }) {

    const lon1 = ToRadian(origin.longitude);
    const lat1 = ToRadian(origin.latitude);
    const lon2 = ToRadian(destination.longitude);
    const lat2 = ToRadian(destination.latitude);

    const deltaLat = lat2 - lat1;
    const delatLon = lon2 - lon1;

    const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2)
    * Math.pow(Math.sin(delatLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}

/**
 * @param degrees The value to convert
 *
 * Returns the value of a degrees parameter converted to radians.
 */
function ToRadian(degrees: number) {
  return degrees * Math.PI / 180;
}
