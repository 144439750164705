import Axios, { CancelTokenSource } from "axios";
import API from "./API";
import { Water, Met, CWOP, Agri, Weewx, Air } from "./interfaces/TransSecDataTypes";
import { Location } from "./interfaces/TransSecDataTypes";
import { MessageCount, MessageBreakdown } from "./interfaces/StatisticTypes";

const WATER_ENDPOINT = "libelium/smartwater";
const MET_ENDPOINT = "met";
const CWOP_ENDPOINT = "cwop";
const AGRI_ENDPOINT = "libelium/smartagri";
const WEEWX_ENDPOINT = "wx";
const AIR_ENDPOINT = "airqual";

const MAX_STATS_TO_DISPLAY = 10;

class Client {
  public static getInstance(): Client {
    if (!Client.instance) {
      Client.instance = new Client();
    }
    return Client.instance;
  }
  private static instance: Client;

  private SMARTWATER: Water[];
  get SmartWater(): Water[] {
    return this.SMARTWATER;
  }
  set SmartWater(water: Water[]) {
    this.SMARTWATER = water;
  }
  
  private AIRQUAL: Air[];
  get AirQual(): Air[] {
    return this.AIRQUAL;
  }
  set AirQual(air: Air[]) {
    this.AIRQUAL = air;
  }

  private METWEA: Met[];
  get MetWea(): Met[] {
    return this.METWEA;
  }
  set MetWea(met: Met[]) {
    this.METWEA = met;
  }

  private CITIWEA: CWOP[];
  get CitiWea(): CWOP[] {
    return this.CITIWEA;
  }
  set CitiWea(cwop: CWOP[]) {
    this.CITIWEA = cwop;
  }

  private SMARTAGRI: Agri[];
  get SmartAgri(): Agri[] {
    return this.SMARTAGRI;
  }
  set SmartAgri(agri: Agri[]) {
    this.SMARTAGRI = agri;
  }

  private WX: Weewx[];
  get wx(): Weewx[] {
    return this.WX;
  }
  set wx(weewx: Weewx[]) {
    this.WX = weewx;
  }
  
  private BASE_URL: string = "" + process.env.REACT_APP_API_BASE_URL;
  get baseUrl(): string {
    return this.BASE_URL;
  }

  private SIGNAL: CancelTokenSource;
  get signal(): CancelTokenSource {
    return this.SIGNAL;
  }

  private MESSAGE_COUNTS: MessageCount[];
  get messageCounts(): MessageCount[] {
    return this.MESSAGE_COUNTS;
  }
  set messageCounts(counts: MessageCount[]) {
    this.MESSAGE_COUNTS = counts;
  }

  private MESSAGE_BREAKDOWN: MessageBreakdown[];
  get messageBreakdown(): MessageBreakdown[] {
    return this.MESSAGE_BREAKDOWN;
  }
  set messageBreakdown(breakdown: MessageBreakdown[]) {
    this.MESSAGE_BREAKDOWN = breakdown;
  }

  private constructor() {
    this.AIRQUAL = [];
    this.SMARTWATER = [];
    this.METWEA = [];
    this.CITIWEA = [];
    this.SMARTAGRI = [];
    this.WX = [];
    this.MESSAGE_COUNTS = [];
    this.MESSAGE_BREAKDOWN = [
      {fill: "#8884d8", name: "Met Eireann Weather Observations", number: 0},
      {fill: "#83a6ed", name: "SmartWater", number: 0},
      {fill: "#82ca9d", name: "CWOP Weather Observations", number: 0},
      {fill: "#d0ed57", name: "SmartAgri", number: 0},
      {fill: "#d0ed57", name: "Weewx", number: 0},
      {fill: "#d0ed57", name: "AirQual", number: 0},
    ];

    this.SIGNAL = Axios.CancelToken.source();
  }

  public fetchData() {
    this.getAirQual();
    this.getSmartWater();
    this.getMetWea();
    this.getCitiWea();
    this.getSmartAgri();
    this.getwx();
    this.updateStatistics();
  }

  private updateStatistics() {
    // Update message count statistics
    const d = new Date().toLocaleTimeString();
    const newCount: MessageCount = {
      date: d,
      messageCount: this.SmartWater.length +
        this.MetWea.length +
        this.CitiWea.length +
        this.wx.length +
        this.AirQual.length +
        this.SmartAgri.length,
    };
    this.messageCounts.push(newCount);
    if (this.messageCounts.length > MAX_STATS_TO_DISPLAY) {
      this.messageCounts.splice(0, this.messageCounts.length - MAX_STATS_TO_DISPLAY);
    }

    // Update message breakdown statistics
    this.messageBreakdown[0].number = this.MetWea.length;
    this.messageBreakdown[1].number = this.SmartWater.length;
    this.messageBreakdown[2].number = this.CitiWea.length;
    this.messageBreakdown[3].number = this.SmartAgri.length;
    this.messageBreakdown[4].number = this.wx.length;
    this.messageBreakdown[5].number = this.AirQual.length;
  }

  private async getSmartWater() {
    try {
      const response = await API.get(this.baseUrl + WATER_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      const data = response.data;
      this.SmartWater = data;
    } catch (err) {
      // TOOD
    }
  }
  
  private async getAirQual() {
    try {
      const response = await API.get(this.baseUrl + AIR_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      this.AirQual = response.data;
    } catch (err) {
      // TODO
    }
  }
  
  private async getwx() {
    try {
      const response = await API.get(this.baseUrl + WEEWX_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      const data = response.data;
      this.wx = data;
    } catch (err) {
      // TOOD
    }
  }

  private async getMetWea() {
    try {
      const response = await API.get(this.baseUrl + MET_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      this.MetWea = response.data;
    } catch (err) {
      // TODO
    }
  }

  private async getSmartAgri() {
    try {
      const response = await API.get(this.baseUrl + AGRI_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      this.SmartAgri = response.data;
    } catch (err) {
      // TODO
    }
  }

  private async getCitiWea() {
    try {
      const response = await API.get(this.baseUrl + CWOP_ENDPOINT, {
        cancelToken: this.signal.token,
      });
      this.CitiWea = response.data;
    } catch (err) {
      // TODO
    }
  }

  /**
   * Performs a reverse geocoding lookup using OSM API, with a given location
   * and zoom level. API documentation available at https://nominatim.org/release-docs/develop/api/Reverse/
   */
  public async reverseGeoCode(location: Location, zoom: number = 18) {
    try {
      const response = await API.get(`https://nominatim.openstreetmap.org/reverse?lat=${location.latitude}&lon=${location.longitude}&format=json&zoom=${zoom}`);
      const data = response.data.address;
      return `${data.city_district}, ${data.county}-${data.country}`;
    } catch (e) {
      // TODO
      return `${location.latitude}, ${location.longitude}`;
    }
  }

}

export default Client;
