import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../animations/transsec-loading.json";
import "./style.css";

class Loader extends Component {
  public render() {
    const defaultOptions = {
      animationData,
      autoplay: true,
      loop: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="loader">
        <Lottie options={defaultOptions}
          height={200}
          width={200}
        />
        <p>Nothing to see here</p>
      </div>
    );
  }
}

export default Loader;
