import L, { LatLng, Map, Point } from "leaflet";
import React, { Component } from "react";
import { ContextProps, withLeaflet } from "react-leaflet";
import { TransSecIts } from "../interfaces/TransSecDataTypes";
import { AveragePoint, GeneratePointsCircle, GeneratePointsSpiral, GetLatLng, ToLayerPoint } from "../utils";
import ItsMarker from "./ItsMarker";

interface IProps extends ContextProps {
  objects: TransSecIts[];
}

export class MarkerCluster extends Component<IProps> {
  public positions: LatLng[];

  public constructor(props: any) {
    super(props);
    this.positions = new Array<LatLng>();
  }

  /**
   * @param objects
   * @param map
   *
   * Creates an array of map layer points corresponding to objects.
   * Indexes will match.
   */
  public GeneratePointArray(objects: TransSecIts[], map: Map) {
    const points = new Array<Point>();
    for (const object of objects) {
      points.push(ToLayerPoint(GetLatLng(object), map));
    }
    return points;
  }

  /**
   * @param map The leaflet map element.
   *
   * Generates the point circle for the Marker Cluster and converts it to LatLng coordinates.
   */
  public GeneratePositions(map: Map) {
    const { objects } = this.props;
    const centre = AveragePoint(this.GeneratePointArray(objects, map));
    const points = objects.length > 0 ?
      GeneratePointsSpiral(objects.length, centre) :
      GeneratePointsCircle(objects.length, centre);
    const positions = new Array<LatLng>();
    for (const point of points) {
      const pos = map.layerPointToLatLng(point);
      positions.push(pos);
    }
    return positions;
  }

  /**
   * @param origin The point to offset.
   *
   * Offset the screen position of a LatLng coordinate. This function is used to centre the starting
   * point of a Polyline to a message marker icon.
   */
  public OffsetPoint(origin: LatLng, map: Map) {
    let point = map.latLngToLayerPoint(origin);
    point = L.point(point.x + 14, point.y + 14);
    return map!.layerPointToLatLng(point);
  }

  public render() {
    const { leaflet, objects } = this.props;
    if (!leaflet) {
      return <div></div>;
    }
    if (!leaflet.map) {
      return <div></div>;
    }
    const map = leaflet.map;
    if (objects.length <= 1) {
      return (
        <div>
          {
            objects.map((object) => {
              return <ItsMarker object={object}/>;
            })
          }
        </div>
      );
    }
    this.positions = this.GeneratePositions(map);

    return (
      <div>
        {
          objects.map((object, index) => {
            return <ItsMarker object={object} position={this.positions[index]}
              offset={this.OffsetPoint(this.positions[index], map)}/>;
          })
        }
      </div>
    );
  }
}

export default withLeaflet(MarkerCluster);
