import { TransSecIts, getDataType } from "../../interfaces/TransSecDataTypes";
import React, { Component, CSSProperties } from "react";
import "./styles.css";
import "../../logo.svg";
import Client from "../../Client";

interface IProps {
  object: TransSecIts;
  style?: CSSProperties;
}

/**
 * MessageCard is used on the Inspector view as a
 * TransSecIts message representation.
 */
class MessageCard extends Component<IProps> {
  private location: string;

  public state = {
    flipCard: false,
  };

  public constructor(props: IProps) {
    super(props);
    this.location = `(${this.props.object.location.latitude}, ${this.props.object.location.longitude})`;
  }

  public async componentDidMount() {
    this.location = await Client.getInstance().reverseGeoCode(
      this.props.object.location
    );
  }

  private toggleFlip() {
    this.setState({
      flipCard: !this.state.flipCard,
    });
  }

  public render() {
    const { style, object } = this.props;
    const bgStyle = {
      backgroundImage: 'url("/images/card-image.png")',
    };
    const flip = this.state.flipCard ? "hover" : "";
    return (
      <div
        className={`container ${flip}`}
        onTouchStart={this.toggleFlip.bind(this)}
        style={style}
      >
        <div className="card">
          <div className="card_front" style={bgStyle}>
            <h1 className="card-symbol">{getDataType(object.dataType)}</h1>
            <span>{this.location}</span>
            <span>{`(${object.location.latitude}, ${object.location.longitude})`}</span>
          </div>

          <div className="card_back">
            <div className="card-text">
              <p>
                <strong>Type:</strong> {getDataType(object.dataType)}
              </p>
              <p>
                <strong>Location:</strong>{" "}
                {`(${object.location.latitude}, ${object.location.longitude})`}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageCard;
