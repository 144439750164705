import { createMuiTheme, createStyles, ThemeProvider, WithStyles, withStyles } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";
import React, { Component } from "react";
import Client from "../../Client";
import NavMenu from "../Navigation/NavMenu";
import Router from "../Router";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#1C1C25" },
    secondary: { main: "#ff4081" },
    text: {
      primary: "#707070",
      secondary: "#FFFFFF",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
  typography: {
    fontFamily: [
      "\"Source Sans Pro\"",
      "sans-serif",
    ].join(","),
  },
});

const styles = () => (
  createStyles({
    navigation: {
      width: theme.spacing(7),
      [theme.breakpoints.up("md")]: {
        width: "300px",
      },
    },
  })
);

class App extends Component<WithStyles<typeof styles>> {

  private _isMounted = false;
  private intervalId: any = 0;

  public async componentDidMount() {
    this._isMounted = true;

    Client.getInstance().fetchData();
    if (this._isMounted) {
      this.intervalId = setInterval(() => {
        Client.getInstance().fetchData();
        this.forceUpdate();
      }, 2000);
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalId);
    Client.getInstance().signal.cancel("API is being cancelled");
  }

  public render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <div className={classes.navigation}>
            <NavMenu/>
          </div>
          <div className="content">
            <Router/>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
