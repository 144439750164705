import "./style.css";
import React, { Component } from "react";

interface Props {
  showSTREAM_Water: boolean;
  showSTREAM_Weather: boolean;
  showMet: boolean;
  showCWOP: boolean;
  showSTREAM_Air: boolean;
  toggleSTREAM_Water: () => void;
  toggleSTREAM_Weather: () => void;
  toggleMet: () => void;
  toggleCWOP: () => void;
  toggleSTREAM_Air: () => void;
}

class Legend extends Component<Props> {
  public render() {
    return (
      <div className="info legend">
        <div
          className={
            this.props.showSTREAM_Water
              ? "legend info-line"
              : "legend info-line invisible-legend"
          }
        >
          <button
            onClick={this.props.toggleSTREAM_Water}
            className="unstyled-button"
          >
            <img
              src="icons/icon2.svg"
              width="18px"
              height="18px"
              alt="CWOP-icon"
            />
            STREAM Water Sensors
          </button>
        </div>
        <div
          className={
            this.props.showSTREAM_Weather
              ? "legend info-line"
              : "legend info-line invisible-legend"
          }
        >
          <button
            onClick={this.props.toggleSTREAM_Weather}
            className="unstyled-button"
          >
            <img
              src="icons/icon1.svg"
              width="18px"
              height="18px"
              alt="WATER-icon"
            />
            STREAM Weather Stations
          </button>
        </div>
        <div
        className={
            this.props.showSTREAM_Air
              ? "legend info-line"
              : "legend info-line invisible-legend"
          }
        >
          <button
            onClick={this.props.toggleSTREAM_Air}
            className="unstyled-button"
          >
            <img
              src="icons/icon1.svg"
              width="18px"
              height="18px"
              alt="AIR-icon"
            />
            STREAM Air Quality Station
          </button>
        </div>
        <div
          className={
            this.props.showMet
              ? "legend info-line"
              : "legend info-line invisible-legend"
          }
        >
          <button
            onClick={this.props.toggleMet}
            className="unstyled-button"
          >
            <img
              src="icons/icon1.svg"
              width="18px"
              height="18px"
              alt="MET-icon"
            />
            Met Eireann Weather Stations
          </button>
        </div>
        <div
      className={
            this.props.showCWOP
              ? "legend info-line"
              : "legend info-line invisible-legend"
          }
        >
          <button
            onClick={this.props.toggleCWOP}
            className="unstyled-button"
          >
            <img
              src="icons/icon1.svg"
              width="18px"
              height="18px"
              alt="WX-icon"
            />
            Citizen Science Weather Stations
          </button>
        </div>
      </div>
    );
  }
}

export default Legend;
