import {
  TransSecIts,
  getDataType,
  DataType,
  Agri,
  CWOP,
  Water,
  Met,
  Weewx,
  Air

} from "../../interfaces/TransSecDataTypes";
import React, { Component } from "react";
import { Popup } from "react-leaflet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import "./style.css";

interface InfoPopupProps {
  object: TransSecIts;
}

class InfoPopup extends Component<InfoPopupProps> {
  public state = {
    buttonState: 0,
  };

  private loadButton() {
    this.setState({ buttonState: 1 }, () => {
      setTimeout(() => {
        this.setState({ buttonState: 2 }, () => {
          setTimeout(() => {
            this.setState({ buttonState: 0 });
          }, 3000);
        });
      }, 2000);
    });
  }

  public render() {
    const { object } = this.props;
    switch (object.dataType) {
      case DataType.Agri:
        const SmartAgri = object as Agri;
    return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {SmartAgri.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Temperature</b>
              </TableCell>
              <TableCell>{SmartAgri.temperature}{" °C"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Humidity</b>
              </TableCell>
              <TableCell>{SmartAgri.humidity}{" %"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 1h Rain</b>
              </TableCell>
              <TableCell>{SmartAgri.rainPrevHr}{" mm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 24h Rain</b>
              </TableCell>
              <TableCell>{SmartAgri.rainPre24Hr}{" mm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Pressure</b>
              </TableCell>
              <TableCell>{SmartAgri.pressure}{" hPa"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Speed</b>
              </TableCell>
              <TableCell>{SmartAgri.windSpeed}{" m/s"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Direction</b>
              </TableCell>
              <TableCell>{SmartAgri.windDir}{" °"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
    case DataType.Water:
        const SmartWater = object as Water;
    return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {SmartWater.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableCell align="center" colSpan={2}>
               <form 
      		target="_blank"
      		action= {SmartWater.graph}
      		method="post" 
      		id="signup">
         	<button type="submit">Graph</button>
                </form>
		</TableCell>
            <TableRow>
              <TableCell>
                 <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
      		<b>Temperature</b>
              </TableCell>
              <TableCell>{SmartWater.temperature}{" °C"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
        case DataType.Weewx:
        const wx = object as Weewx;
    return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {wx.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableCell align="center" colSpan={2}>
               <form 
      		target="_blank"
      		action= {wx.graph}
      		method="post" 
      		id="signup">
         	<button type="submit">Graph</button>
                </form>
		</TableCell>
            <TableRow>
              <TableCell>
                <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
               <b>Temperature</b>
              </TableCell>
              <TableCell>{wx.outTemp_C}{" °C"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Humidity</b>
              </TableCell>
              <TableCell>{wx.outHumidity}{" %"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 1h Rain</b>
              </TableCell>
              <TableCell>{wx.hourRain_cm}{" cm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 24h Rain</b>
              </TableCell>
              <TableCell>{wx.rain24_cm}{" cm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Pressure</b>
              </TableCell>
              <TableCell>{wx.pressure_mbar}{" hPa"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Speed</b>
              </TableCell>
              <TableCell>{wx.windSpeed_kph}{" km/h"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Gust</b>
              </TableCell>
              <TableCell>{wx.windGust_kph}{" km/h"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Dir</b>
              </TableCell>
              <TableCell>{wx.windDir}{" °"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Dew-Point</b>
              </TableCell>
              <TableCell>{wx.dewpoint_C}{" °C"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
          case DataType.Met:
        const MetWea = object as Met;
        return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {MetWea.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          	<TableCell align="center" colSpan={2}>
               <form 
      		target="_blank"
      		action= {MetWea.graph}
      		method="post" 
      		id="signup">
         	<button type="submit">Graph</button>
                </form>
		</TableCell>
            <TableRow>
              <TableCell>
                <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Temperature</b>
              </TableCell>
              <TableCell>{MetWea.temperature}{" °C"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Humidity</b>
              </TableCell>
              <TableCell>{MetWea.humidity}{" %"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 1h Rain</b>
              </TableCell>
              <TableCell>{MetWea.rain_1h}{" mm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Pressure</b>
              </TableCell>
              <TableCell>{MetWea.pressure}{" hPa"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Speed</b>
              </TableCell>
              <TableCell>{MetWea.windSpeed}{" m/s"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
              <b>Wind Gust</b>
              </TableCell>
              <TableCell>{MetWea.windGust}{" m/s"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Direction</b>
              </TableCell>
              <TableCell>{MetWea.windDir}{" °"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
        case DataType.Air:
        const AirQual = object as Air;
        return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {AirQual.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
        case DataType.CWOP:
        const CitiWea = object as CWOP;
        return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {CitiWea.locality}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableCell align="center" colSpan={2}>
               <form 
      		target="_blank"
      		action= {CitiWea.graph}
      		method="post" 
      		id="signup">
         	<button type="submit">Graph</button>
                </form>
		</TableCell>
            <TableRow>
              <TableCell>
                <b>Updated</b>
                </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Temperature</b>
              </TableCell>
              <TableCell>{CitiWea.temperature}{" °C"}
              </TableCell>
            </TableRow>
            <TableRow>
            <TableCell>
                <b>Pressure</b>
              </TableCell>
              <TableCell>{CitiWea.pressure}{" hPa"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
              <b>Humidity</b>
              </TableCell>
              <TableCell>{CitiWea.humidity}{" %"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 1h Rain</b>
              </TableCell>
              <TableCell>{CitiWea.rain_1h}{" cm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last 24h Rain</b>
              </TableCell>
              <TableCell>{CitiWea.rain_24h}{" cm"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
              <b>Rain since midnight</b>
              </TableCell>
              <TableCell>{CitiWea.rain_since_midnight}{" cm"}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>
              <b>Wind Chill</b>
              </TableCell>
              <TableCell>{CitiWea.wind_chill}{" °C"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Speed</b>
              </TableCell>
              <TableCell>{CitiWea.wind_speed}{" km/h"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Gust</b>
              </TableCell>
              <TableCell>{CitiWea.wind_gust}{" km/h"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Wind Direction</b>
              </TableCell>
              <TableCell>{CitiWea.wind_direction}{" °"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Dew Point</b>
              </TableCell>
              <TableCell>{CitiWea.dew_point}{" °C"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
    );
      default:
        return (
      <Popup className="info-popup">
        <Table cellPadding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">    
                  {"Unknown"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Last contact</b>
              </TableCell>
              <TableCell>
                {new Date(object.timestamp).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popup>
        );
  }
}
}

export default InfoPopup;
