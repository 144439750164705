import React, { Component } from "react";
import {
  LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, RadialBarChart, RadialBar, ResponsiveContainer,
} from "recharts";
import "./style.css";
import Client from "../../../Client";

class StatisticsPage extends Component {

  private _isMounted = false;
  private intervalId: any = 0;

  public state = {
    messageBreakdown: Client.getInstance().messageBreakdown,
    messageCounts: Client.getInstance().messageCounts,
  };

  public async componentDidMount() {
    this._isMounted = true;

    Client.getInstance().fetchData();
    if (this._isMounted) {
      this.intervalId = setInterval(() => {
        this.setState({messageBreakdown: Client.getInstance().messageBreakdown,
          messageCounts: Client.getInstance().messageCounts});
        this.forceUpdate();
      }, 2000);
    }
  }

  public render() {
    const style = {
      left: 350,
      lineHeight: "24px",
    };
    return (
      <div className="stats-page-container">
        <div className="chart-container delay-0">
          <p>
            <span className="chart-title">
              Connected objects
            </span>
          </p>
        <ResponsiveContainer width="100%">
        <LineChart
          data={this.state.messageCounts}
          key={"" + Math.random()}
        >
          <XAxis dataKey="date"/>
          <Tooltip/>
          <CartesianGrid stroke="f5f5f5"/>
          <Line type="monotone" dataKey="messageCount" stroke="#ff4081" yAxisId={0} isAnimationActive={false}/>
        </LineChart>
        </ResponsiveContainer>
        </div>
        <div className="chart-container delay-1">
          <p>
            <span className="chart-title">
              Object types
            </span>
          </p>
          <ResponsiveContainer width="100%">
            <RadialBarChart data={this.state.messageBreakdown}
              innerRadius={20} outerRadius={80} barSize={10}
            >
              <RadialBar background dataKey="number" legendType="circle"/>
              <Tooltip/>
              {
                (window.innerWidth >= 1024) &&
                <Legend iconSize={10} width={120} height={140}
                  layout="vertical" verticalAlign="middle" wrapperStyle={style}
                />
              }
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default StatisticsPage;
