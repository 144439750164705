import MyLocationIcon from "@material-ui/icons/MyLocation";
import L, { latLng, latLngBounds, LeafletMouseEvent, point } from "leaflet";
import React, { Component } from "react";
import ContainerDimensions from "react-container-dimensions";
import { Map, Marker, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import Client from "../../Client";
import { TransSecIts } from "../../interfaces/TransSecDataTypes";
import { GetLatLng } from "../../utils";
import ItsMarker from "../ItsMarker";
import Legend from "../Legend/Legend";
import MarkerClusterGroup from "../MarkerClusterGroup";
import "./style.css";

const defaultZoom = 15;

export class MapControl extends Component {
  public state = {
    lat: 52.16235,
    location: latLng(0, 0),
    lon: -7.15244,
    showSTREAM_Water: true,
    showSTREAM_Weather: true,
    showSTREAM_Air: true,
    showMet: true,
    showCWOP: true,
    showMarker: false,
  };

  public map: any;
  public dialog: any;
  public marker: any;

  private _isMounted = false;

  private isPopupOpen = false;

  private constructor(props: any) {
    super(props);
    this.map = React.createRef();
    this.dialog = React.createRef();
    this.marker = React.createRef<Marker>();

    this.toggleSTREAM_Water = this.toggleSTREAM_Water.bind(this);
    this.toggleSTREAM_Air = this.toggleSTREAM_Air.bind(this);
    this.toggleCWOP = this.toggleCWOP.bind(this);
    this.toggleSTREAM_Weather = this.toggleSTREAM_Weather.bind(this);
    this.toggleMet = this.toggleMet.bind(this);
  
  }

  private onNotifyClick() {
    if (this.dialog.current) {
      this.dialog.current.open(this.onCloseCallback);
    }
  }

  private toggleSTREAM_Water() {
    this.setState({
      showSTREAM_Water: !this.state.showSTREAM_Water,
    });
  }

  private toggleSTREAM_Weather() {
    this.setState({
      showSTREAM_Weather: !this.state.showSTREAM_Weather,
    });
  }

  private toggleMet() {
    this.setState({
      showMet: !this.state.showMet,
    });
  }


  private toggleCWOP() {
    this.setState({
      showCWOP: !this.state.showCWOP,
    });
  }
  
  private toggleSTREAM_Air() {
    this.setState({
      showSTREAM_Air: !this.state.showSTREAM_Air,
    });
  }
  
  private onClick(event: LeafletMouseEvent) {
    this.setState(
      {
        location: event.latlng,
        showMarker: true,
      },
      () => {
        if (this.dialog.current) {
          this.dialog.current.open(this.onCloseCallback);
        }
      }
    );
  }

  private onCloseCallback() {
    this.setState({ showMarker: false });
  }

  /**
   * Centre the map view by panning to fit all object markers in view.
   */
  private centreMap(): void {
    const objects = (Client.getInstance().SmartWater as TransSecIts[]).concat(
      Client.getInstance().MetWea,
      Client.getInstance().CitiWea,
      Client.getInstance().SmartAgri,
      Client.getInstance().wx,
      Client.getInstance().AirQual
    );

    if (objects.length < 1) {
      return;
    }

    // Finds the map bounds in order to fit all objects onscreen
    const markerBounds = latLngBounds([GetLatLng(objects[0])]);
    objects.forEach((object) => {
      markerBounds.extend(GetLatLng(object));
    });

    this.map.current.leafletElement.flyToBounds(markerBounds, {
      animate: true,
      padding: point(36, 36),
    });
  }

  // Updates the position of the manually-placed marker.
  private updateMarkerPosition(): void {
    const marker = this.marker.current;
    if (marker !== null) {
      this.setState({
        location: marker.leafletElement.getLatLng(),
      });
    }
  }

  public componentDidMount() {
    this._isMounted = true;

    try {
      if (this._isMounted) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.setState({
            lat: position.coords.latitude,
            location: latLng(
              position.coords.latitude,
              position.coords.longitude
            ),
            lon: position.coords.longitude,
          });
        });
      }
    } catch (e) {
      // TODO: Handle error
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    let mapReady = false;
    if (this.map.current) {
      if (this.map.current.leafletElement) {
        mapReady = true;
      }
    }
    let zoomLevel = defaultZoom;
    if (this.map.current !== null) {
      if (this.map.current.leafletElement._zoom !== zoomLevel) {
        zoomLevel = this.map.current.leafletElement._zoom;
      }
    }

    // Only update map position if there is no popups open
    if (!this.isPopupOpen) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (
          this.state.lat !== position.coords.latitude ||
          this.state.lon !== position.coords.longitude
        ) {
          this.setState({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        }
      });
    }

    let markerObjects = new Array<TransSecIts>();
    if (this.state.showSTREAM_Water) {
      markerObjects = markerObjects.concat(
        Client.getInstance().SmartWater as TransSecIts[]
      );
    }
    
    if (this.state.showSTREAM_Weather) {
      markerObjects = markerObjects.concat(
        Client.getInstance().wx as TransSecIts[],
        Client.getInstance().SmartAgri as TransSecIts[]
      );
    }
    
    if (this.state.showSTREAM_Air) {
      markerObjects = markerObjects.concat(
        Client.getInstance().AirQual as TransSecIts[]
      );
    }

    if (this.state.showMet) {
      markerObjects = markerObjects.concat(
        Client.getInstance().MetWea as TransSecIts[]
      );
    }
    
    if (this.state.showCWOP) {
      markerObjects = markerObjects.concat(
        Client.getInstance().CitiWea as TransSecIts[]
      );
    }
    


    return (
      <div className="map">
        <ContainerDimensions>
          {({ width, height }) => (
            <Map
              ref={this.map}
              center={[this.state.lat, this.state.lon]}
              zoom={zoomLevel}
              minZoom={5}
              maxZoom={19}
              attributionControl={true}
              zoomControl={false}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              style={{ width, height }}
              easeLinearity={0.35}
              onContextMenu={this.onClick.bind(this)}
            >
              <TileLayer 
              Title={L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              })}
              url="https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
              attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
              {mapReady && (
                <MarkerClusterGroup>
                  {markerObjects.map((object) => {
                    return <ItsMarker object={object} />;
                  })}
                </MarkerClusterGroup>
              )}
              
              <Control position="bottomright">
                <div>
                  <button
                    onClick={() => this.centreMap()}
                    className="btn"
                    type="button"
                  >
                    <MyLocationIcon className="btn" fontSize="large" />
                  </button>
                </div>
              </Control>
              <Control position="topleft">
                <Legend
                  showSTREAM_Water={this.state.showSTREAM_Water}
                  showSTREAM_Weather={this.state.showSTREAM_Weather}
                  showSTREAM_Air={this.state.showSTREAM_Air}
                  showMet={this.state.showMet}
                  showCWOP={this.state.showCWOP}
 
                  toggleSTREAM_Water={this.toggleSTREAM_Water}
                  toggleSTREAM_Weather={this.toggleSTREAM_Weather}
                  toggleSTREAM_Air={this.toggleSTREAM_Air}
                  toggleMet={this.toggleMet}
                  toggleCWOP={this.toggleCWOP}
                />
              </Control>
            </Map>
          )}
        </ContainerDimensions>
      </div>
    );
  }
}

export default MapControl;
