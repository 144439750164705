import { createStyles, Theme,
  WithStyles, withStyles, Tab } from "@material-ui/core";
import React, { Component } from "react";
import Client from "../../../Client";
import MessageCard from "../../MessageCard/MessageCard";
import "./style.css";
import TabPanel from "../../TabPanel/TabPanel";
import { StyledTabs } from "../../StyledTabs/StyledTabs";
import Loader from "../../Loader/Loader";

const styles = (theme: Theme) => (
  createStyles({
    colContent: {
      margin: theme.spacing(3),
    },
    column: {
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: "25%",
      },
      flex: 1,
      width: "100%",
    },
    header: {
      paddingBottom: theme.spacing(3),
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    parent: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      height: "100%",
      padding: theme.spacing(3),
      width: "100%",
    },
  })
);

class InspectorPage extends Component<WithStyles<typeof styles>> {
  public state = {
    value: 0,
  };

  private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    this.setState({ value: newValue });
  }

  public render() {
    const { value } = this.state;
    return (
      <div className="tabs-root">
        <StyledTabs
          value={value}
          onChange={this.handleTabChange.bind(this)}
          centered
          scrollable
        >
          <Tab disableRipple label="Events"/>
          <Tab disableRipple label="Infrastructure"/>
          <Tab disableRipple label="Pedestrians"/>
          <Tab disableRipple label="Vehicles"/>
        </StyledTabs>
        <TabPanel value={value} index={0}>
          {
            (Client.getInstance().MetWea.length === 0) &&
            <Loader/>
          }
          {
            Client.getInstance().MetWea.map((risk, index) =>
              <MessageCard object={risk} style={ { animationDelay: `${index * 0.6}s`} }/>,
            )
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {
            (Client.getInstance().CitiWea.length === 0) &&
            <Loader/>
          }
          {
            Client.getInstance().CitiWea.map((inf, index) =>
              <MessageCard object={inf} style={ { animationDelay: `${index * 0.6}s`} }/>,
            )
          }
        </TabPanel>
        <TabPanel value={value} index={2}>
          {
            (Client.getInstance().SmartWater.length === 0) &&
            <Loader/>
          }
          {
            Client.getInstance().SmartWater.map((ped, index) =>
              <MessageCard object={ped} style={ { animationDelay: `${index * 0.6}s`} }/>,
            )
          }
        </TabPanel>
        <TabPanel value={value} index={3}>
          {
            (Client.getInstance().SmartAgri.length === 0) &&
            <Loader/>
          }
          {
            Client.getInstance().SmartAgri.map((veh, index) =>
              <MessageCard object={veh} style={ { animationDelay: `${index * 0.6}s`} }/>,
            )
          }
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(InspectorPage);
