import { Map } from "leaflet";
import React, { Component } from "react";
import { ContextProps, withLeaflet } from "react-leaflet";
import { TransSecIts } from "../interfaces/TransSecDataTypes";
import { CalculateDistance, GetLatLng, ItsPoint, ToLayerPoint } from "../utils";
import ItsMarker from "./ItsMarker";
import MarkerCluster from "./MarkerCluster";

const minDistance = 30;

interface IProps extends ContextProps {
  minDistance?: number;
}

export class MarkerClusterGroup extends Component<IProps> {
  /**
   * Generates clusters of markers which are within close proximity. A cluster is generated
   * when multiple markers are within the specified minimum distance of each other to prevent
   * marker overlap. This check is performed on every marker. Any markers which do not overlap
   * with others are placed into their own individual cluster for rendering.
   */
  public ClusterMarkers(map: Map) {
    const { children } = this.props;
    let objects = new Array<TransSecIts>();
    if (this.props.children) {
      objects = this.GatherObjects(children as ItsMarker[]);
    }

    let found = false;
    const layerPts = new Array<ItsPoint>();
    for (const obj of objects) {
      layerPts.push({
        object: obj,
        point: ToLayerPoint(GetLatLng(obj), map),
      });
    }

    const clusters = new Array<ItsPoint[]>();
    for (let i = 0; i < layerPts.length - 1; i++) {
      for (let j = i + 1; j < layerPts.length; j++) {
        // Check distance between points
        const distance = CalculateDistance(layerPts[i].point,
          layerPts[j].point);
        if (distance < minDistance) {
          // Before adding points to cluster, check if either point is already in an
          // existing cluster. If  point [i] is within cluster, then point [j] shoud be
          // added to that cluster
          for (const cluster of clusters) {
            if (cluster.includes(layerPts[i])) {
              if (!cluster.includes(layerPts[j])) {
                cluster.push(layerPts[j]);
              }
              break;
            }
          }

          found = false;
          // Search all groups for layerPts[j]
          for (const cluster of clusters) {
            if (cluster.includes(layerPts[i]) || cluster.includes(layerPts[j])) {
              found = true;
              break;
            }
          }
          // If neither point is within an existing cluster, create a new cluster
          // and add both points [i] and [j]
          if (!found) {
            const cluster = new Array<ItsPoint>();
            cluster.push(layerPts[i]);
            cluster.push(layerPts[j]);
            clusters.push(cluster);
          }
        }
      }
    }

    // Add any solo points to its own group
    for (const point of layerPts) {
      found = false;
      // Check if each point is in any cluster
      for (const cluster of clusters) {
        if (cluster.includes(point)) {
          found = true;
          break;
        }
      }
      // If point is not found in any existing cluster, add to new one
      if (!found) {
        const solo = new Array<ItsPoint>();
        solo.push(point);
        clusters.push(solo);
      }
    }

    const msgs = new Array<TransSecIts[]>();
    for (const cluster of clusters) {
      const msgGroup = new Array<TransSecIts>();
      for (const msgPoint of cluster) {
        const message = msgPoint.object;
        msgGroup.push(message);
      }
      msgs.push(msgGroup);
    }
    return msgs;
  }

  /**
   * Gather all of the child ItsMarker components and retrieve the TransSecIts object from each.
   */
  public GatherObjects(children: ItsMarker[]) {
    const objects = new Array<TransSecIts>();
    for (const marker of children) {
      objects.push(marker.props.object);
    }
    return objects;
  }

  public render() {
    const { leaflet } = this.props;
    if (!leaflet) {
      return <div></div>;
    }
    if (!leaflet.map) {
      return <div></div>;
    }
    const map = leaflet.map;

    const clusterer = this.ClusterMarkers(map);

    return (
      <div>
        {
          clusterer.map((cluster) => {
            return <MarkerCluster objects={cluster}/>;
          })
        }
      </div>
    );
  }
}

export default withLeaflet(MarkerClusterGroup);
