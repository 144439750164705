import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App/App";
import "./index.css";

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh",
  `${vh}px`);

window.addEventListener("resize", () => {
  const h = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh",
  `${h}px`);
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);
