import React, { Component } from "react";
import MapControl from "../../MapControl/MapControl";
import "./styles.css";

class MapPage extends Component {
  public render() {
    return (
      <div className="map-container">
        <MapControl/>
      </div>
    );
  }
}

export default MapPage;
