import { Badge, createMuiTheme, createStyles, Divider, Drawer, List, ListItem,
  ListItemIcon, ListItemText, MuiThemeProvider, Theme, WithStyles, withStyles } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import SearchIcon from "@material-ui/icons/Search";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Client from "../../Client";
import "./style.css";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontFamily: "\"Open Sans\", sans-serif",
  },
});

const styles = (theme: Theme) => (
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: "300px",
      },
      width: theme.spacing(7),
    },
    drawerBackground: {
      [theme.breakpoints.up("md")]: {
        width: "300px",
      },
      background: "white",
      overflowX: "hidden",
      width: theme.spacing(7),
    },
    drawerHeader: {
      alignItems: "center",
      color: "#010101",
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(3),
      ...theme.mixins.toolbar,
    },
    footerItem: {
      color: "#707070",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "8px",
        paddingTop: "8px",
      },
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    link: {
      color: "unset",
      textDecoration: "none",
    },
    listItemIcon: {
      color: "#707070",
    },
    logo: {
      padding: "0 5px",
    },
    secondary: {
      color: "rgba(112, 112, 112, 0.5)",
    },
    selectedLink: {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
      borderRight: "5px solid #ff4081",
      boxShadow: "0px 5px 15px rgba(0,0,0,0.07)",
    },
  })
);

interface IProps extends WithStyles<typeof styles> {
    classes: {
        content: string;
        drawer: string;
        drawerBackground: string;
        drawerHeader: string;
        footerItem: string;
        link: string;
        listItemIcon: string;
        logo: string;
        secondary: string;
        selectedLink: string;
    };
}

class NavMenu extends Component<IProps> {
  public render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={darkTheme}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          classes={{ paper: classes.drawerBackground}}
          className={classes.drawer}
        >
          <NavLink to="/" className={classes.link}>
            <div className={`${classes.drawerHeader} drawer-item delay-0`}>
              <img
              src="icons/stream-icon.svg"
              width="80px"
              height="60px"
              alt="stream-icon"
            />
            </div>
          </NavLink>
          <Divider/>
          <div id="navigation-menu-content-container" className={this.props.classes.content}>
            <List>
              <ListItem button component={NavLink} className={`${classes.footerItem} drawer-item delay-1`}
                to="/" activeClassName={classes.selectedLink} exact key="Map-Link">
                <ListItemIcon className={classes.listItemIcon}>
                  <MapIcon/>
                </ListItemIcon>
                <ListItemText primary="Map"/>
              </ListItem>
              <ListItem button component={NavLink} className={`${classes.footerItem} drawer-item delay-2`}
                to="/inspect" activeClassName={classes.selectedLink} exact key="Inspector-Link">
                <ListItemIcon className={classes.listItemIcon}>
                  <SearchIcon/>
                </ListItemIcon>
                <ListItemText primary="Inspector"/>
              </ListItem>
              <ListItem button component={NavLink} className={`${classes.footerItem} drawer-item delay-3`}
                to="/statistics" activeClassName={classes.selectedLink} exact key="Statistics-Link">
                <ListItemIcon className={classes.listItemIcon}>
                  <ShowChartIcon/>
                </ListItemIcon>
                <ListItemText primary="Statistics"/>
              </ListItem>
            </List>
          </div>
          <div id="footer-container">
            <Divider/>
            <List>
                <ListItem key="number-of-Stream-sensors" className={`${classes.footerItem} drawer-item delay-4`}>
                <ListItemIcon className={classes.listItemIcon}>
              		<img
              		src="icons/STREAM-icon.svg"
              		width="40px"
              		height="30px"
              		alt="stream-icon"
            		/>
                </ListItemIcon>
                <ListItemText primary="Stream sensors"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              <ListItem key="number-of-Water-sensors" className={`${classes.footerItem} drawer-item delay-4`}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Badge badgeContent={Client.getInstance().SmartWater.length} color="default" showZero>
              		<img
              		src="icons/icon2.svg"
              		width="20px"
              		height="20px"
              		alt="stream-icon"
            		/>
                    </Badge>
                </ListItemIcon>
                <ListItemText secondary="Water sensors"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              <ListItem key="number-of-Weather-sensors" className={`${classes.footerItem} drawer-item delay-4`}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Badge badgeContent={Client.getInstance().SmartAgri.length+Client.getInstance().wx.length} color="default" showZero>
              		<img
              		src="icons/icon1.svg"
              		width="20px"
              		height="20px"
              		alt="stream-icon"
            		/>
                    </Badge>
                </ListItemIcon>
                <ListItemText secondary="Weather Stations"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              <ListItem key="number-of-Airqual-sensors" className={`${classes.footerItem} drawer-item delay-4`}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Badge badgeContent={Client.getInstance().AirQual.length} color="default" showZero>
              		<img
              		src="icons/icon1.svg"
              		width="20px"
              		height="20px"
              		alt="stream-icon"
            		/>
                    </Badge>
                </ListItemIcon>
                <ListItemText secondary="Air Quality Stations"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              </List>
              <List>
              <ListItem key="number-of-Stream-sensors" className={`${classes.footerItem} drawer-item delay-5`}>
                <ListItemIcon className={classes.listItemIcon}>
              		<img
              		src="icons/MET-icon.svg"
              		width="30px"
              		height="30px"
              		alt="stream-icon"
            		/>
                  </ListItemIcon>
                <ListItemText primary="Met Eireann"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              <ListItem key="number-of-Weather-sensors" className={`${classes.footerItem} drawer-item delay-5`}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Badge badgeContent={Client.getInstance().MetWea.length} color="default" showZero>
              		<img
              		src="icons/icon1.svg"
              		width="20px"
              		height="20px"
              		alt="stream-icon"
            		/>
                    </Badge>
                </ListItemIcon>
                <ListItemText secondary="Weather Stations"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              </List>
              <List>
              <ListItem key="number-of-CWOP-sensors" className={`${classes.footerItem} drawer-item delay-6`}>
                <ListItemIcon className={classes.listItemIcon}>
              		<img
              		src="icons/CWOP-icon.svg"
              		width="30px"
              		height="30px"
              		alt="stream-icon"
            		/>
                  </ListItemIcon>
                <ListItemText primary="Citizen Science"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
              <ListItem key="number-of-Weather-sensors" className={`${classes.footerItem} drawer-item delay-6`}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Badge badgeContent={Client.getInstance().CitiWea.length} color="default" showZero>
              		<img
              		src="icons/icon1.svg"
              		width="20px"
              		height="20px"
              		alt="stream-icon"
            		/>
                    </Badge>
                </ListItemIcon>
                <ListItemText secondary="Weather Stations"
                  classes={{ secondary: classes.secondary}}/>
              </ListItem>
            </List>
          </div>
          <NavLink to="/" className={classes.link}>
            <div className={`${classes.drawerHeader} drawer-item delay-0`}>
              <img
              src="icons/Group-icon.svg"
              width="290px"
              height="72px"
              alt="stream-icon"
            />
            </div>
          </NavLink>
        </Drawer>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(NavMenu);
