import L, { LatLng } from "leaflet";
import { DriftMarker } from "leaflet-drift-marker";
import React, { Component } from "react";
import { TransSecIts } from "../interfaces/TransSecDataTypes";
import { GetLatLng, GetMarkerIcon } from "../utils";
import InfoPopup from "./InfoPopup/InfoPopup";

interface IProps {
  object: TransSecIts;
  offset?: LatLng;
  position?: LatLng;
}

export class ItsMarker extends Component<IProps> {
  public position: LatLng;
  private markerId: string;

  public icon: L.Icon<L.IconOptions>;

  private constructor(props: any) {
    super(props);
    this.position = this.props.position ? this.props.position : GetLatLng(this.props.object);
    this.icon = GetMarkerIcon(this.props.object);
    this.markerId = "" + this.props.object.timestamp + "-" + this.props.object.location;
  }

  public OffsetOriginPoint(origin: L.Point) {
    return L.point(origin.x + 10, origin.y + 10);
  }

  public render() {
    const { object, position } = this.props;

    this.position = position ? position : GetLatLng(object);
    this.icon = GetMarkerIcon(object);

    return (
      <DriftMarker icon={this.icon} key={"" + this.markerId + "-marker"} position={this.position} duration={500}>
        <InfoPopup object={object}/>
      </DriftMarker>
    );
  }
}

export default ItsMarker;
