import React, { Component } from "react";
import "./style.css";

export interface TabPanelProps {
  children?: any;
  index: number;
  value: number;
}

class TabPanel extends Component<TabPanelProps> {
  public render() {
    const { children, index, value, ...other } = this.props;
    return (
      <div
        className={value === index ? "tab-panel-root": ""}
        role="tabpanel"
        hidden={value !== index}
        { ...other }
      >
        { value === index && [...this.props.children] }
      </div>
    );
  }
}

export default TabPanel;
