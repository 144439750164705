import React, { ChangeEvent } from "react";
import { withStyles, Tabs } from "@material-ui/core";

interface StyledTabsProps {
  centered?: boolean;
  scrollable?: boolean;
  value: number;
  onChange: (event: ChangeEvent<{}>, newValue: number) => void;
}

export const StyledTabs = withStyles({
  indicator: {
    "& > span": {
      backgroundColor: "#ff4081",
      maxWidth: 40,
      width: "100%",
    },
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);
